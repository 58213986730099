import styled from "@emotion/styled";
import {
  Box,
  Button,
  Input,
  Stack,
  Typography,
  CircularProgress,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Stepper from "../Common/Stepper";
import {
  VERTICAL,
  COMPLETE,
  NEXT,
  SALES_ORDER_STATUS,
} from "../constants/Constants";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SalesOrderItems from "../components/SalesOrder/SalesOrderItems";
import SalesSummeryCustomer from "../components/SalesOrderCustomer/SalesSummeryCustomer";
import ItemSummeryCustomer from "../components/SalesOrderCustomer/ItemSummeryCustomer";
import { useNavigate, useParams } from "react-router-dom";
import SuccessModal from "../Common/Modals/SuccessModal";
import FailedModal from "../Common/Modals/FailedModal";
import AlertToast from "../components/common/Toast";
import { apiConstants } from "../constants";
import { authHeader, getUserToken } from "../helpers";
import ProgressCircularWithBackdrop from "../Common/ProgressCircular/ProgressCircularWithBackdrop";
import { useNotification } from "../Contexts/GlobalNotificationContext";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

// Styled components
const StyledContainer = styled(Box)({
  width: "100%",
  minWidth: "500px",
  border: "1px dashed #C4C4C4",
  height: "379px",
});

const StyledStepperContainer = styled(Stack)({
  width: "200px",
  borderRight: "1px solid #C4C4C4",
  minHeight: "500px",
});

const StyledTableContainer = styled(Stack)({
  marginTop: "10px",
});

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.neutral.light,
  height: "60px",
}));

const StyledImage = styled("img")({
  display: "block",
  height: "85%",
  objectFit: "fill",
  paddingLeft: "10px",
});

const SalesOrderCustomer = () => {
  const [sizes, setSizes] = useState([]);
  const [data, setData] = useState({ items: [] });
  // Removed PURCHASE_ORDER – now the only step is COMPLETE.
  const [steps, setSteps] = useState([COMPLETE]);
  const [activeStep, setActiveStep] = useState(0);
  const [mounted, setMounted] = useState(false);
  const [isSuccess, setIssuccess] = useState(false);
  const [success, setSuccess] = useState("");
  const [comments, setComments] = useState("");
  const [loading, setLoading] = useState(true);
  const [uploadItemDetailsFile, setUploadItemDetailsFile] = useState("");
  const [salesOrderStatus, setSalesOrderStatus] = useState({});

  const { addNotification } = useNotification();
  const navigator = useNavigate();
  let { id } = useParams();

  const [toastState, setToastState] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });
  const [toastMessage, setToastMessage] = useState("");
  const handleToastClose = () => setToastState({ open: false });

  const [file, setFile] = useState({});
  const [downloadFile, setDownloadFile] = useState([]);
  const [totalDelivery, setTotalDelivery] = useState(0);
  const [openProgressCircular, setOpenProgressCircular] = useState(false);

  const getItem = useCallback(() => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    fetch(`${apiConstants.API_URL}/customer/sales-orders/${id}`, requestOptions)
      .then((response) => response.text())
      .then((text) => {
        const data = text && JSON.parse(text);
        setSalesOrderStatus(data?.data?.status);
        setTotalDelivery(data?.data?.estimated_shipping_charges);
        fetch(`${apiConstants.API_URL}/sizes`, requestOptions)
          .then((response) => response.text())
          .then((text) => {
            const res = text && JSON.parse(text);
            setSizes(res?.data);
            const sizesArray = res?.data;
            if (data?.data.comment) {
              setComments(data?.data.comment);
            }
            if (data?.data?.file) {
              const imageUrl = data?.data.file.split("/");
              setFile({
                image: data?.data.file,
                image_url: data?.data.file_url,
                name: data?.data.file.split("/")[2].split("_")[1],
              });
            }
            if (data?.data?.quotation?.quotation_files) {
              setDownloadFile(data?.data?.quotation?.quotation_files);
            }
            let newData = data?.data.items.map((item) => {
              let arrangedItems = [];
              let itemDetailsWithQuantityArrangedItems = [];
              if (item?.item_details.length !== 0 && item?.flag_name_no === 1) {
                item?.item_details?.forEach((q) => {
                  sizesArray?.forEach((s) => {
                    if (q.size?.id === s.id) {
                      const obj = {
                        ...q,
                        size_id: q.size?.id,
                        selectedSize: { id: s.id, name: s.name },
                      };
                      arrangedItems.push(obj);
                    }
                  });
                });
                itemDetailsWithQuantityArrangedItems = [
                  {
                    size_id: 0,
                    quantity: "",
                    selectedSize: { id: 0, name: "" },
                  },
                ];
              } else if (
                item?.item_details.length !== 0 &&
                item?.flag_name_no === 0
              ) {
                let sizeWithQuantity = [];
                item?.item_details?.forEach((q) => {
                  sizesArray?.forEach((s) => {
                    if (q.size?.id === s.id) {
                      const obj = {
                        size_id: q.size?.id,
                        quantity: 1,
                        selectedSize: { id: s.id, name: s.name },
                      };
                      sizeWithQuantity.push(obj);
                    }
                  });
                });
                let hash = Object.create(null);
                let result = [];
                sizeWithQuantity.forEach((o) => {
                  if (!hash[o.size_id]) {
                    hash[o.size_id] = {
                      size_id: o.size_id,
                      quantity: 0,
                      selectedSize: o.selectedSize,
                    };
                    result.push(hash[o.size_id]);
                  }
                  hash[o.size_id].quantity += +o.quantity;
                });
                itemDetailsWithQuantityArrangedItems = result;
                for (let i = 0; i < item?.qty; i++) {
                  let newObt = {
                    number: "",
                    name: "",
                    size_id: 0,
                    id: "",
                    selectedSize: { id: 0, name: "" },
                  };
                  arrangedItems.push(newObt);
                }
              } else if (item?.item_details.length === 0) {
                for (let i = 0; i < item?.qty; i++) {
                  let newObt = {
                    number: "",
                    name: "",
                    size_id: 0,
                    id: "",
                    selectedSize: { id: 0, name: "" },
                  };
                  arrangedItems.push(newObt);
                }
                itemDetailsWithQuantityArrangedItems = [
                  {
                    size_id: 0,
                    quantity: "",
                    selectedSize: { id: 0, name: "" },
                  },
                ];
              }
              let newOb = {
                ...item,
                price_id: item?.price?.id,
                color_id: item?.color?.id,
                item_details: arrangedItems,
                itemDetailsWithQuantity: itemDetailsWithQuantityArrangedItems,
                item_details_file_path:
                  item?.item_details_file_path === null
                    ? ""
                    : item?.item_details_file_path,
                item_details_file_url:
                  item?.item_details_file_url === null
                    ? ""
                    : item?.item_details_file_url,
              };
              return newOb;
            });
            let newDataObjec = {
              ...data?.data,
              items: newData,
            };
            setData(newDataObjec);
            const allItems = [
              ...(data?.data?.items || []),
              ...(data?.data?.custom_items || []),
            ];
            let newAppa = allItems.map((item) =>
              item?.price?.item?.name ? item?.price?.item?.name : item.name
            );
            setSteps([...newAppa, ...steps]);
          });
      });
  }, [steps, id]);

  const allItems = [...(data?.items || []), ...(data?.custom_items || [])];
  const displayData = allItems[activeStep] ?? {};
  const finalStage = COMPLETE === steps[activeStep];
  // Since Purchase Order section is removed, isPurchase is always false.
  const isPurchase = false;
  const isCompleted = COMPLETE === steps[activeStep - 1];
  const [lastPages, setLastPages] = useState(false);
  const [editStatus, setEditStatus] = useState(false);
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(false);
  const [orderItemsError, setOrderItemsError] = useState({
    status: false,
    message: "",
    index: 0,
  });

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
      getItem();
    }
    const checkStageType = finalStage || isCompleted;
    setLastPages(checkStageType);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, [getItem, mounted, finalStage, isCompleted]);

  const isApproved = useMemo(() => {
    const approved = salesOrderStatus.keyword !== SALES_ORDER_STATUS.PENDING;
    if (approved) {
      setDisabledSubmitBtn(true);
    }
    return approved;
  }, [salesOrderStatus]);

  useEffect(() => {
    if (lastPages) {
      setDisabledSubmitBtn(false);
    }
  }, [lastPages]);

  const title = !lastPages ? displayData?.price?.item?.name : "";
  const image = !lastPages ? displayData?.image_url : "";
  const image2 = !lastPages ? displayData?.image_2_url : "";
  const image3 = !lastPages ? displayData?.image_3_url : "";
  const buttonText = lastPages ? "CONFIRM SALES ORDER" : NEXT;

  // Submit action and related functions remain unchanged
  const submitAction = () => {
    setOpenProgressCircular(true);
    let imagePath = "";
    const { items, order_method, organization, quotation, status } = data;
    if (status.id === 2) {
      setToastMessage("Order is already accepted");
      setToastState({
        open: true,
        vertical: "top",
        horizontal: "right",
      });
    } else {
      const oldItems = items?.map((item) => {
        let newObt;
        if (item?.flag_name_no) {
          const formateArray = item?.item_details?.map((c) => {
            return {
              number: c.number,
              name: c.name,
              size_id: c.size_id,
              id: c.id ? c.id : "",
            };
          });
          newObt = {
            ...item,
            item_details: formateArray,
          };
        } else {
          let array = [];
          item?.itemDetailsWithQuantity?.forEach((q) => {
            let obj;
            for (let i = 0; i < q.quantity; i++) {
              obj = {
                number: "",
                name: "",
                size_id: q.size_id,
                id: q.id ? q.id : "",
              };
              array.push(obj);
            }
          });
          newObt = {
            ...item,
            item_details: array,
          };
        }
        delete newObt.sales_order_item_extra_charges;
        return newObt;
      });
      oldItems.forEach(function (v) {
        delete v.itemDetailsWithQuantity;
        if (v?.item_details_file_path === "") {
          delete v?.item_details_file_path;
          delete v?.item_details_file_url;
        }
      });
      const token = getUserToken();
      const requestImageOptions = {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
        },
        body: file?.image,
      };
      if (file?.image_url) {
        fetch(
          `${apiConstants.API_URL}/file/sales-order`,
          requestImageOptions
        ).then((response) => {
          response.text().then((text) => {
            const data = text && JSON.parse(text);
            if (!response.ok) {
              const error = (data && data.error) || response.statusText;
              const errorMessage = Object.values(error).map(
                (errorKey) => errorKey[0]
              );
              setOpenProgressCircular(false);
              setToastMessage(errorMessage[0]);
              setToastState({
                open: true,
                vertical: "top",
                horizontal: "right",
              });
            } else {
              submitSalesOrder(data?.data?.path, oldItems);
            }
          });
        });
      } else {
        submitSalesOrder(imagePath, oldItems);
      }
    }
  };

  const submitSalesOrder = (imagePath, oldItems) => {
    const {
      items,
      order_method,
      organization,
      quotation,
      status,
      estimated_shipping_charges,
    } = data;
    let reqObe;
    if (imagePath !== "") {
      reqObe = {
        order_method_id: order_method?.id,
        invoice_id: null,
        organization_id: organization?.id,
        quotation_id: quotation?.id,
        status_id: 2,
        items: oldItems,
        comment: comments,
        file: imagePath,
        estimated_shipping_charges: estimated_shipping_charges,
      };
    } else {
      reqObe = {
        order_method_id: order_method?.id,
        invoice_id: null,
        organization_id: organization?.id,
        quotation_id: quotation?.id,
        status_id: 2,
        comment: comments,
        items: oldItems,
        estimated_shipping_charges: estimated_shipping_charges,
      };
    }
    const requestOptions = {
      method: "PUT",
      headers: authHeader(),
      body: JSON.stringify(reqObe),
    };
    fetch(
      `${apiConstants.API_URL}/customer/sales-orders/${id}`,
      requestOptions
    ).then((response) => {
      response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
          if (response.status === 401) {
            // logout()
          }
          const error = (data && data.error) || response.statusText;
          const errorMessage = Object.values(error).map(
            (errorKey) => errorKey[0]
          );
          setOpenProgressCircular(false);
          setToastMessage(errorMessage);
          setToastState({
            open: true,
            vertical: "top",
            horizontal: "right",
          });
        } else {
          setOpenProgressCircular(false);
          setSuccess(data?.data?.id);
          setIssuccess(true);
          addNotification({
            message: {
              title: "Sales Order Accepted Successfully!",
              body: `Sales Order #${data?.data?.id} is accepted successfully. Your account manager will contact and guide you through the next steps`,
              extra: "Thank you for your order.",
            },
            type: "success-model",
          });
          setActiveStep(steps.length);
          setDisabledSubmitBtn(true);
          navigator("/sales-orders");
        }
      });
    });
  };

  const nextAction = () => {
    let length = steps.length;
    if (activeStep < length - 1) {
      setActiveStep(activeStep + 1);
      setEditStatus(true);
      return;
    }

    if (activeStep === length - 1) {
      submitAction();
    }
  };

  const preAction = () => {
    setActiveStep(activeStep - 1);
  };

  const updateComments = (e) => {
    const { target } = e;
    setComments(target?.value);
  };

  const downloadUsingAnchorElement = async (index) => {
    const anchor = document.createElement("a");
    anchor.href = downloadFile[index].file_url;
    anchor.download = "*";
    document.body.appendChild(anchor);
    anchor.click();
  };

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "400px",
          }}
        >
          <CircularProgress size={50} />
        </Box>
      ) : (
        <Stack direction={"row"} spacing={5}>
          <StyledStepperContainer
            direction={"column"}
            sx={{ minWidth: "160px" }}
          >
            <Stepper
              activeStep={activeStep}
              orientation={VERTICAL}
              steps={isApproved ? steps.slice(0, -1) : steps}
              alternativeLabel={false}
            />
          </StyledStepperContainer>
          <Stack style={{ textAlign: "left", width: "100%" }} spacing={4}>
            <Typography fontSize={"28px"} fontWeight={500}>
              {title}
            </Typography>
            {!lastPages && (
              <StyledContainer
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {!image ? <div>No Image</div> : <StyledImage src={image} />}
                {!image2 ? <div>No Image2</div> : <StyledImage src={image2} />}
                {!image3 ? <div>No Image3</div> : <StyledImage src={image3} />}
              </StyledContainer>
            )}

            <StyledTableContainer spacing={4}>
              {/* Render SalesOrderItems */}
              {!lastPages && (
                <SalesOrderItems
                  sizes={sizes}
                  item_details={displayData?.item_details}
                  displayData={displayData}
                  setData={setData}
                  data={data}
                  activeStep={activeStep}
                  editStatus={editStatus}
                  error={orderItemsError}
                  setError={setOrderItemsError}
                  setDisabledSubmitBtn={setDisabledSubmitBtn}
                  readOnly={isApproved}
                />
              )}

              {/* Render Comments */}
              {!isApproved && (
                <Stack>
                  <Typography style={{ fontSize: "20px", fontWeight: 700 }}>
                    Comments
                  </Typography>
                  <Typography style={{ fontSize: "15px" }}>
                    Please specify if you have any additional request, special
                    instructions or changes etc.
                  </Typography>
                  <Stack style={{ marginTop: "20px" }} spacing={4}>
                    <Typography style={{ fontSize: "15px" }}>
                      Your Comments
                    </Typography>
                    <Input value={comments} onChange={updateComments} />
                  </Stack>
                </Stack>
              )}
            </StyledTableContainer>
          </Stack>

          <Stack spacing={2}>
            {downloadFile && downloadFile?.length !== 0 && (
              <Box>
                <Typography>Pattern File</Typography>
                {downloadFile?.map((i, index) => (
                  <Box
                    key={i?.id}
                    sx={{
                      display: "flex",
                      padding: "5px",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ display: "flex" }}>
                      <InsertDriveFileIcon
                        color="primary"
                        sx={{ marginRight: "5px" }}
                      />
                      <Typography variant="span">{i?.name}</Typography>
                    </Box>
                    <Button onClick={() => downloadUsingAnchorElement(index)}>
                      Download File
                    </Button>
                  </Box>
                ))}
              </Box>
            )}

            {/* Item Summary */}
            {displayData && !lastPages && (
              <ItemSummeryCustomer {...displayData} />
            )}

            {/* Sales Summary */}
            <SalesSummeryCustomer
              data={data}
              displayData={displayData}
              activeStep={activeStep}
              totalDelivery={totalDelivery}
            />

            <StyledButton
              variant="contained"
              color="success"
              endIcon={<ArrowForwardIcon />}
              onClick={nextAction}
              disabled={disabledSubmitBtn || (isApproved && lastPages)}
            >
              {buttonText}
            </StyledButton>
            {activeStep > 0 && (
              <Button startIcon={<ArrowBackIcon />} onClick={preAction}>
                BACK
              </Button>
            )}
          </Stack>
          <ProgressCircularWithBackdrop open={openProgressCircular} />

          <FailedModal />
          <AlertToast
            toastMessage={toastMessage}
            toastState={toastState}
            handleClose={handleToastClose}
          />
        </Stack>
      )}
    </>
  );
};

export default SalesOrderCustomer;
