import * as React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import { useStyles } from "../../../../Theme/Style";
import { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { useNotification } from "../../../../Contexts/GlobalNotificationContext";
import {
  useGetTrimDetailsQuery,
  useSubmitTrimDetailsMutation,
} from "../../../../services/api/production-order";

const TrimDetailsComponent = ({ prodId }) => {
  const classes = useStyles();
  const { addNotification } = useNotification();

  // Fetch trim details using the production order ID
  const { data: trimData, isLoading } = useGetTrimDetailsQuery({ id: prodId });
  const [submitTrimDetails] = useSubmitTrimDetailsMutation();

  // Local state to store the trim rows
  const [trimItems, setTrimItems] = useState([]);

  // When the API data is available, initialize the trim items
  useEffect(() => {
    if (trimData?.trims) {
      const initialItems = trimData.trims.map((t) => ({
        item: t.item || null,
        trimType: t.trim || null,
        color: t.color || null,
        position: t.position || null,
      }));
      setTrimItems(initialItems);
    }
  }, [trimData]);

  // Add a new empty row
  const addNewRow = () => {
    setTrimItems([
      ...trimItems,
      { item: null, trimType: null, color: null, position: null },
    ]);
  };

  // Update a specific field in a row
  const handleChange = (event, fieldName, index, value) => {
    const updatedItems = [...trimItems];
    updatedItems[index][fieldName] = value;
    setTrimItems(updatedItems);
  };

  // Remove a row from the table
  const deleteRow = (index) => {
    const updatedItems = [...trimItems];
    updatedItems.splice(index, 1);
    setTrimItems(updatedItems);
  };

  // Validate each row and submit via the API
  const handleSubmit = async () => {
    try {
      const isValid = trimItems.every(
        (item) =>
          item.item?.id &&
          item.trimType && // You can check for an id if trimType is an object
          item.color?.id &&
          item.position?.id
      );
      if (!isValid) {
        throw new Error("Please fill out all fields in each row.");
      }
      await submitTrimDetails({
        production_order_id: prodId,
        trims: trimItems,
      }).unwrap();
      addNotification({
        message: "Trim details submitted successfully!",
        type: "success",
      });
    } catch (error) {
      addNotification({
        message: error.message || "Failed to submit trim details.",
        type: "error",
      });
    }
  };

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
        aria-controls="trim-details-content"
        id="trim-details-header"
      >
        <Grid container className={classes.accordionSummaryTextContainer}>
          <Grid item xs={4}>
            Trim Details
          </Grid>
          <Grid item xs={4}>
            修剪细节
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        {/* Table header */}
        <Box className={classes.tableHeader}>
          <Grid container>
            <Grid item xs={3}>
              <Typography>Item</Typography>
              <Typography>物品</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>Trim Type</Typography>
              <Typography>修剪类型</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>Color</Typography>
              <Typography>颜色</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>Position</Typography>
              <Typography>位置</Typography>
            </Grid>
          </Grid>
        </Box>

        {/* Render each trim detail row */}
        {trimItems.map((row, index) => (
          <Box key={index} className={classes.row} sx={{ mt: 1 }}>
            <Grid container spacing={2} alignItems="center">
              {/* Item Selection */}
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <Autocomplete
                    options={trimData?.items || []}
                    getOptionLabel={(option) =>
                      `${option.name} - ${option.chinese_name || ""}`
                    }
                    value={row.item}
                    onChange={(event, value) =>
                      handleChange(event, "item", index, value)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Item"
                        variant="standard"
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              {/* Trim Type Selection */}
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <Autocomplete
                    options={trimData?.trimTypes || []}
                    getOptionLabel={(option) =>
                      `${option.name} - ${option.chinese_name || ""}`
                    }
                    value={row.trimType}
                    onChange={(event, value) =>
                      handleChange(event, "trimType", index, value)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Trim Type"
                        variant="standard"
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              {/* Color Selection */}
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <Autocomplete
                    options={trimData?.colors || []}
                    getOptionLabel={(option) =>
                      `${option.english_name} - ${option.chinese_name || ""}`
                    }
                    value={row.color}
                    onChange={(event, value) =>
                      handleChange(event, "color", index, value)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Color"
                        variant="standard"
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              {/* Position Selection */}
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <Autocomplete
                    options={trimData?.positions || []}
                    getOptionLabel={(option) =>
                      `${option.name} - ${option.chinese_name || ""}`
                    }
                    value={row.position}
                    onChange={(event, value) =>
                      handleChange(event, "position", index, value)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Position"
                        variant="standard"
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              {/* Remove Row Button */}
              <Grid item xs={12} sx={{ textAlign: "right" }}>
                <Button
                  onClick={() => deleteRow(index)}
                  disabled={trimItems.length === 1}
                  startIcon={<RemoveCircleOutline />}
                  color="error"
                  variant="outlined"
                >
                  Remove
                </Button>
              </Grid>
            </Grid>
          </Box>
        ))}

        {/* Add New Row and Save buttons */}
        <Box sx={{ mt: 2 }}>
          <Button
            variant="outlined"
            onClick={addNewRow}
            startIcon={<AddCircleOutline />}
          >
            Add New Row
          </Button>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Button variant="contained" onClick={handleSubmit}>
            Save
          </Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default TrimDetailsComponent;
