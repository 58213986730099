import * as React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Grid,
  Button,
  Typography,
  Paper,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useStyles } from "../../../Theme/Style";
import { useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import {
  useGetSpecificationQuery,
  useSubmitSpecificationMutation, // for uploading the file and saving specification
} from "../../../services/api/production-order";
import { useNotification } from "../../../Contexts/GlobalNotificationContext";
import { getUserToken } from "../../../helpers";
import { apiConstants } from "../../../constants";

const SpecificationComponent = ({ prodId }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { addNotification } = useNotification();

  // Load any existing specification file data.
  const {
    data: specData,
    isLoading,
    error,
  } = useGetSpecificationQuery({ id: prodId });

  // Mutation hook to save the specification (i.e. send production order id and file path).
  const [saveSpecification] = useSubmitSpecificationMutation();

  // Local state to store the URL (for displaying in iframe) and the file path (for saving)
  const [specFileUrl, setSpecFileUrl] = React.useState(null);
  const [specFilePath, setSpecFilePath] = React.useState(null);

  // When API data loads, if a specification file URL already exists, update the state.
  React.useEffect(() => {
    if (specData?.specification_url) {
      setSpecFileUrl(specData.specification_url);
    }
  }, [specData]);

  // Helper function to validate file extension
  const isExcelFile = (file) => {
    const allowedExtensions = [".xls", ".xlsx"];
    const fileName = file.name.toLowerCase();
    return allowedExtensions.some((ext) => fileName.endsWith(ext));
  };

  // Set up the dropzone for Excel file uploads.
  const onDrop = React.useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        // Validate that the file is an Excel file
        if (!isExcelFile(file)) {
          addNotification({
            message:
              "Invalid file type. Please upload an Excel file (.xls or .xlsx).",
            type: "error",
          });
          return;
        }

        // Create a FormData instance and append the file.
        const formData = new FormData();
        formData.append("file", file);

        let token = getUserToken();
        fetch(`${apiConstants.API_URL}/file/production-order`, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
          },
          body: formData,
        })
          .then((response) => response.json())
          .then((data) => {
            setSpecFileUrl(data?.data?.url);
            setSpecFilePath(data?.data?.path);
            addNotification({
              message: "File uploaded successfully!",
              type: "success",
            });
          })
          .catch((err) => {
            addNotification({
              message: "Failed to upload file.",
              type: "error",
            });
          });
      }
    },
    [addNotification]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ".xls,.xlsx", // Accept only Excel files.
  });

  // Handler for the Save button – sends only the production order ID and file path.
  const handleSave = async () => {
    if (!specFilePath) {
      addNotification({ message: "No file uploaded to save.", type: "error" });
      return;
    }
    try {
      // Send the production order ID and file path to the save endpoint.
      await saveSpecification({
        production_order_id: prodId,
        specification: specFilePath,
      }).unwrap();
      addNotification({
        message: "Specification saved successfully!",
        type: "success",
      });
    } catch (err) {
      addNotification({
        message: "Failed to save specification.",
        type: "error",
      });
    }
  };

  // Handler to remove the uploaded file.
  const handleRemove = () => {
    setSpecFileUrl(null);
    setSpecFilePath(null);
    addNotification({ message: "File removed successfully!", type: "info" });
  };

  // Handler for downloading the static sample Excel file.
  const handleDownloadSample = () => {
    // Replace with your sample file URL. If the file is in your public folder,
    // you can use: `${process.env.PUBLIC_URL}/sample.xlsx`
    const sampleFileUrl = `${process.env.PUBLIC_URL}/sample/sample_specification.xlsx`;
    const anchor = document.createElement("a");
    anchor.href = sampleFileUrl;
    anchor.download = "sample.xlsx";
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  if (isLoading) {
    return (
      <Box sx={{ textAlign: "center", padding: "20px" }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error" sx={{ textAlign: "center", padding: "20px" }}>
        Failed to load specification data.
      </Typography>
    );
  }

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
        aria-controls="specification-panel-content"
        id="specification-panel-header"
        sx={{ backgroundColor: "#512879" }}
      >
        <Grid container className={classes.accordionSummaryTextContainer}>
          <Grid item xs={4}>
            Specification
          </Grid>
          <Grid item xs={4}>
            规格
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        {/* Display drag-and-drop area if no file has been uploaded yet */}
        {!specFileUrl && (
          <Box
            {...getRootProps()}
            sx={{
              border: "2px dashed #ccc",
              padding: "20px",
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <Typography>Drop the file here ...</Typography>
            ) : (
              <Typography>
                Drag & drop your specification Excel file here, or click to
                select a file.
              </Typography>
            )}
          </Box>
        )}

        {/* If a file URL is available, display the file in an iframe using Office Online viewer */}
        {specFileUrl && (
          <Paper sx={{ mt: 2, height: "600px" }}>
            <iframe
              src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
                specFileUrl
              )}`}
              title="Specification File"
              width="100%"
              height="100%"
              style={{ border: "none" }}
            />
          </Paper>
        )}

        {/* Static Sample Excel File Download Button */}
        <Box sx={{ mt: 2 }}>
          <Button variant="outlined" onClick={handleDownloadSample}>
            Download Sample Excel File
          </Button>
        </Box>

        {/* Buttons for saving and removing the file */}
        <Box className={classes.buttonContainer} sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            {specFileUrl && (
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  color="error"
                  className={classes.submitBtn}
                  onClick={handleRemove}
                >
                  Remove File
                </Button>
              </Grid>
            )}
            <Grid item xs={2}>
              <Button
                variant="contained"
                className={classes.submitBtn}
                onClick={handleSave}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default SpecificationComponent;
