import styled from "@emotion/styled";
import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { addOrder, addSingleItem } from "../../store/Store/orderSlice";
import { LIST_ITEM } from "../../constants/Constants";
import axios from "../../API/axios";
import { convertToPlaces } from "../../helpers/helpers";
import DeleteIcon from "@mui/icons-material/Delete";

const StyledButton = styled(Button)({
  color: "white",
});

const style = {
  background: "#FFFFFF",
  boxShadow:
    "0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
  borderRadius: "4px",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "798px",
  padding: "10px",
};

const CustomLineModal = ({ ...props }) => {
  const { open, handleClose } = props;
  const { activeSport } = useSelector((state) => state.orders);
  const [extra, setExtra] = useState([]);
  const [selected, setSelected] = useState({});
  const [extraCharges, setExtraCharges] = useState([]);
  const [newItem, updateItem] = useState(LIST_ITEM);

  const getExtra = () => {
    axios.get(`/extra-charges`).then((res) => {
      setExtra(res.data.data);
    });
  };

  useEffect(() => {
    getExtra();
  }, []);

  const dispatch = useDispatch();

  const handleSave = () => {
    dispatch(addSingleItem(newItem));
    dispatch(addOrder(newItem));
    clearAll();
  };

  const onChange = (event) => {
    const { value, id } = event.target;

    if (id === "unit_price") {
      updateItem((state) => ({
        ...state,
        unit_price: value * 100,
        original_unit_price: value * 100,
        original_total_price: value * state.qty * 100,
        total_price: value * state.qty * 100,
        id: Date.now(),
      }));
    } else
      updateItem((state) => ({ ...state, [id]: value, sport_id: activeSport }));
  };

  const onChangeExtra = (e) => {
    const { value } = e.target;
    setSelected(() => value);
  };

  const addExtraTotal = () => {
    if (selected.code) {
      let newArray = [...extraCharges];
      newArray.push(selected);
      setExtraCharges(() => [...newArray]);

      setSelected({});
      let totalExtra = 0;
      newArray.map((e) => {
        totalExtra = totalExtra + e.cost;
        return "";
      });
      updateItem((state) => ({
        ...state,
        extra_charges: totalExtra,
        extra_charges_list: [...newArray],
      }));
    }
  };

  const removeExtra = (index) => {
    let newArray = [];
    if (index) {
      newArray = extraCharges.slice(index);
    }

    setExtraCharges([...newArray]);
    let totalExtra = 0;
    newArray.map((e) => {
      totalExtra = totalExtra + e.cost;

      return "";
    });
    updateItem({
      ...newItem,
      extra_charges: totalExtra,
      extra_charges_list: [...newArray],
    });
  };

  const clearAll = () => {
    setSelected({});
    handleClose();
    setExtraCharges([]);
    updateItem(LIST_ITEM);
  };

  const showExtra = extraCharges.length > 0;
  const enableAdd = newItem.name && newItem.unit_price > 0 && newItem.qty > 0;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack spacing={3}>
          <Stack direction={"row"} spacing={1}>
            <Typography
              id="modal-modal-title"
              fontWeight={500}
              fontSize={"20px"}
            >
              Add Custom Line Item
            </Typography>
          </Stack>
          <Stack direction={"row"} justifyContent={"space-between"} spacing={4}>
            <TextField
              label="Line Item Name"
              variant="standard"
              fullWidth
              id="name"
              onChange={onChange}
            />
            <TextField
              label="Quantity"
              variant="standard"
              fullWidth
              id="qty"
              onChange={onChange}
              type="number"
              min="1"
              autoComplete="off"
            />
            <TextField
              label="Price"
              variant="standard"
              fullWidth
              id="unit_price"
              onChange={onChange}
              type="number"
            />
          </Stack>

          <Stack direction={"row"} justifyContent={"space-between"}>
            <Button color="success" onClick={() => clearAll()}>
              CLOSE
            </Button>
            <StyledButton
              color="success"
              onClick={() => handleSave()}
              variant="contained"
              disabled={!enableAdd}
            >
              SAVE AND ADD TO QUOTATION
            </StyledButton>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};

CustomLineModal.prototype = {
  open: PropTypes.bool,

  handleClose: PropTypes.func.isRequired,
};
CustomLineModal.defaultProps = {
  open: false,
};

export default CustomLineModal;
