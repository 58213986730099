import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link, Typography, Box, IconButton, Button } from "@mui/material";
import { authHeader } from "../../../helpers";
import { apiConstants } from "../../../constants";
import { Grid } from "@mui/material";
import { getFabrics, getTrims, getLabels } from "../../../helpers";
import Intro from "../../../components/atoms/Intro/Intro.component";
import Content from "../../../components/layout/pageLayout/Content/Content.component";
import { makeStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import moment from "moment";
import ProductImage from "../../../components/admin/ProductionOrders/ProductImage/ProductImage.component";
import ProductionData from "../../../components/admin/ProductionOrders/ProductionData/ProductionData.component";
import DeleteIcon from "@mui/icons-material/Delete";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import EmproideryComponent from "./EmproideryComponent";
import SublimationComponent from "./SublimationComponent";
import FabricDetailsComponent from "./FabricDetailsComponent";
import TrimDetailsComponent from "./TrimDetailsComponent";
import LabelComponent from "./LabelComponent";
import SpecialInstructionComponent from "./SpecialInstructionComponent";
import SpecificationComponent from "./SpecificationComponent";

const useStyles = makeStyles({
  imageContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "16px",
    marginTop: "20px",
    justifyContent: "center",
  },
  imageItem: {
    width: "30%",
    maxWidth: "300px",
    position: "relative",
  },
  deleteIcon: {
    position: "absolute",
    top: 0,
    right: 0,
    cursor: "pointer",
  },
  editInput: {
    width: "100%",
    padding: "5px",
    fontSize: "16px",
  },
  actions: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
});

const ViewProductionOrder = () => {
  const classes = useStyles();
  // get production order id
  const { id } = useParams();

  // loading state
  const [isLoading, setIsLoading] = useState(false);
  const [productionOrder, setProductionOrder] = useState({});
  const [fabrics, setFabrics] = useState([]);
  const [trims, setTrims] = useState([]);
  const [labels, setLabels] = useState([]);
  const [instructions, setInstructions] = useState("");
  const [isEditingStyle, setIsEditingStyle] = useState(false);
  const [newStyle, setNewStyle] = useState(productionOrder?.style || "");
  const [uploadedImages, setUploadedImages] = useState([]);
  const [images, setImages] = useState({
    image_url: null,
    image_2_url: null,
    image_3_url: null,
  });

  // get production order details
  useEffect(() => {
    const getProductionOrder = () => {
      setIsLoading(true);

      const requestOptions = {
        method: "GET",
        headers: authHeader(),
      };

      fetch(`${apiConstants.API_URL}/production-orders/${id}`, requestOptions)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.text();
        })
        .then((text) => {
          const data = text && JSON.parse(text);
          setProductionOrder(data?.data);
          setIsLoading(false);
          setNewStyle(data.data.style || "");
          setImages({
            image_url: data.data.sales_order_items[0]?.image_url,
            image_2_url: data.data.sales_order_items[0]?.image_2_url,
            image_3_url: data.data.sales_order_items[0]?.image_3_url,
          });
        })
        .catch((error) => {
          console.error("Fetch error:", error);
          setIsLoading(false);
        });
    };

    getProductionOrder();
  }, [id]);

  // get details
  useEffect(() => {
    // fabrics
    const loadFabrics = () => {
      const fabricData = getFabrics(
        productionOrder?.is_old_po,
        productionOrder?.po_fields
      );
      setFabrics(fabricData);
    };

    // trims
    const loadTrims = () => {
      const trimData = getTrims(
        productionOrder?.is_old_po,
        productionOrder?.po_fields
      );
      setTrims(trimData);
    };

    // labels
    const loadLabels = () => {
      const labelData = getLabels(productionOrder?.labels);
      setLabels(labelData);
    };

    // instructions
    const loadInstructions = () => {
      const insData =
        productionOrder?.special_instructions !== ""
          ? productionOrder?.special_instructions
          : "No special instructions";
      setInstructions(insData);
    };

    loadFabrics();
    loadTrims();
    loadLabels();
    loadInstructions();
  }, [productionOrder]);

  useEffect(() => {
    if (productionOrder?.style) {
      setNewStyle(productionOrder.style);
    }
  }, [productionOrder.style]);

  const updateStyle = () => {
    const requestOptions = {
      method: "PUT",
      headers: {
        ...authHeader(),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ style: newStyle }),
    };

    fetch(
      `${apiConstants.API_URL}/production-orders/${id}/style`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to update style");
        }
        return response.json();
      })
      .then(() => {
        setProductionOrder((prev) => ({ ...prev, style: newStyle }));
        setIsEditingStyle(false);
      })
      .catch((error) => {
        console.error("Error updating style:", error);
        setIsEditingStyle(false);
      });
  };

  const onFileChange = (e, imageKey) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    const token = authHeader().Authorization.split(" ")[1];

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: formData,
    };

    fetch(`${apiConstants.API_URL}/images/productionorder`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setImages((prev) => ({ ...prev, [imageKey]: data.data.path }));
      })
      .catch((error) => console.error("Image upload error:", error));
  };

  const removeImage = (imageKey) => {
    setImages((prev) => ({ ...prev, [imageKey]: null }));
  };

  const updateImages = () => {
    const requestOptions = {
      method: "PUT",
      headers: {
        ...authHeader(),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(images),
    };

    fetch(
      `${apiConstants.API_URL}/production-orders/${id}/images`,
      requestOptions
    )
      .then((response) => {
        window.location.reload();
        if (!response.ok) throw new Error("Failed to update images");
        return response.json();
      })
      .then(() => {
        console.log("Images updated successfully");
        // reload page
        window.location.reload();
      })
      .catch((error) => console.error("Image update error:", error));
  };

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href="/admin/production-orders/"
    >
      Production Orders
    </Link>,
    <Typography key="2">Production Order #{id}</Typography>,
  ];

  return (
    <>
      <Intro
        pageTitle={`Production Order #${id}`}
        pageTitleShort="Production Order"
        breadcrumbs={breadcrumbs}
        additionalWidgets=""
      />

      <Content>
        <Grid container spacing={4}>
          {productionOrder && (
            <Grid item xs={12}>
              <Box className={classes.orderItemDetailsBar}>
                <Grid container>
                  <Grid item xs={6}>
                    <Box className={classes.label}>
                      Production Order Number | 生产订单号
                    </Box>
                    <Box className={classes.detail}>{productionOrder?.id}</Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box className={classes.label}>Date | 日期</Box>
                    <Box className={classes.detail}>
                      {moment(productionOrder?.created_at).format("DD/MM/YYYY")}
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box className={classes.label}>Style | 风格</Box>
                    <Box className={classes.actions}>
                      {isEditingStyle ? (
                        <>
                          <input
                            type="text"
                            value={newStyle}
                            onChange={(e) => setNewStyle(e.target.value)}
                            className={classes.editInput}
                          />
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={updateStyle}
                            startIcon={<SaveIcon />}
                          >
                            Save
                          </Button>
                        </>
                      ) : (
                        <>
                          <Box className={classes.detail}>
                            {productionOrder?.style || "N/A"}
                          </Box>
                          <IconButton
                            onClick={() => setIsEditingStyle(true)}
                            size="small"
                          >
                            <EditIcon />
                          </IconButton>
                        </>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          )}

          {productionOrder && (
            <Grid item xs={12}>
              <Box>
                <Typography variant="h4">Production Order #{id}</Typography>
                <Box className={classes.imageContainer}>
                  {["image_url", "image_2_url", "image_3_url"].map(
                    (key, index) => (
                      <div key={key} className={classes.imageItem}>
                        {images[key] ? (
                          <>
                            <img
                              src={images[key]}
                              alt={`Image ${index + 1}`}
                              width="100%"
                            />
                            <IconButton
                              className={classes.deleteIcon}
                              onClick={() => removeImage(key)}
                              size="small"
                              color="error"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </>
                        ) : (
                          <Typography variant="body2" color="textSecondary">
                            No Image
                          </Typography>
                        )}
                        <Button
                          variant="contained"
                          component="label"
                          color="primary"
                          startIcon={<AddPhotoAlternateIcon />}
                          style={{ marginTop: "10px" }}
                        >
                          Upload
                          <input
                            type="file"
                            hidden
                            onChange={(e) => onFileChange(e, key)}
                          />
                        </Button>
                      </div>
                    )
                  )}
                </Box>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={updateImages}
                  style={{ marginTop: "20px" }}
                >
                  Save Images
                </Button>
              </Box>
            </Grid>
          )}

          <Grid item xs={12}>
            <FabricDetailsComponent prodId={id} />
          </Grid>

          <Grid item xs={12}>
            <TrimDetailsComponent prodId={id} />
          </Grid>

          <Grid item xs={12}>
            <LabelComponent prodId={id} />
          </Grid>

          <Grid item xs={12}>
            <SpecialInstructionComponent prodId={id} />
          </Grid>

          <Grid item xs={12}>
            <SpecificationComponent prodId={id} />
          </Grid>

          <Grid item xs={12}>
            <EmproideryComponent prodId={id} />
          </Grid>

          <Grid item xs={12}>
            <SublimationComponent prodId={id} />
          </Grid>
        </Grid>
      </Content>
    </>
  );
};

export default ViewProductionOrder;
