import React from "react";
import Notification from "../Common/Notification";
import {
  Button,
  CardContent,
  Stack,
  Typography,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/styles";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import CheckIcon from "@mui/icons-material/Check";
import { apiConstants } from "../constants";
import { getUserToken } from "../helpers";

const StyledBoxContent = styled(Stack)({
  height: "auto",
  minHeight: "142px",
  padding: "19px 30px",
  borderRadius: "8px",
  justifyContent: "space-around",
  textAlign: "center",
  border: "1px dashed #9E9E9E",
  backgroundColor: "#efefef",
});

/**
 * DropzoneWithPreview
 *
 * Renders a dropzone and preview area along with a hidden input that listens for paste events.
 * The paste icon changes to a check mark only while its hidden input is focused.
 */
const DropzoneWithPreview = ({
  item,
  index,
  place,
  handleImageUpload,
  clearSelectedFile,
}) => {
  // Ref for the hidden paste input.
  const pasteInputRef = React.useRef(null);
  // State to track whether this dropzone's hidden input is focused.
  const [isFocused, setIsFocused] = React.useState(false);

  // Handler for paste events (CTRL+V).
  const handlePaste = (event) => {
    event.preventDefault();
    const clipboardItems = event.clipboardData.items;
    for (let i = 0; i < clipboardItems.length; i++) {
      const clipboardItem = clipboardItems[i];
      if (clipboardItem.kind === "file") {
        const file = clipboardItem.getAsFile();
        if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
          handleImageUpload(file, item, index, place);
          break;
        }
      }
    }
  };

  // (Optional) handle copy events.
  const handleCopy = async (event) => {
    if (item?.[`image_${place}_url`]) {
      event.preventDefault();
      try {
        const response = await fetch(item[`image_${place}_url`]);
        const blob = await response.blob();
        await navigator.clipboard.write([
          new ClipboardItem({ [blob.type]: blob }),
        ]);
        console.log("Image copied to clipboard.");
      } catch (err) {
        console.error("Error copying image: ", err);
      }
    }
  };

  // Configure react-dropzone.
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/png, image/jpeg",
    onDrop: (acceptedFiles) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        handleImageUpload(acceptedFiles[0], item, index, place);
      }
    },
  });

  return (
    <Stack direction="row" spacing={2} sx={{ marginBottom: "10px" }}>
      {/* Left: Dropzone area */}
      <div>
        <StyledBoxContent
          {...getRootProps({
            onPaste: handlePaste,
            onCopy: handleCopy,
            tabIndex: 0, // make the container focusable
          })}
          sx={{ cursor: "pointer" }}
        >
          <input {...getInputProps()} />
          <Typography fontSize={"14px"}>
            Drag & drop or click to upload
          </Typography>
          <Typography fontSize={"11px"}>(Accepted: PNG, JPEG)</Typography>
          <Stack direction={"row"} spacing={2} justifyContent="space-evenly">
            <Button
              variant="outlined"
              color="error"
              onClick={() => clearSelectedFile(index, item, place)}
            >
              CLEAR
            </Button>
            <Button
              variant="outlined"
              style={{ width: "246px", pointerEvents: "none" }}
              disabled={!!item?.[`image_${place}`]}
            >
              UPLOAD IMAGE
            </Button>
          </Stack>
        </StyledBoxContent>
        {/* Hidden input for capturing paste events */}
        <input
          ref={pasteInputRef}
          onFocus={() => {
            setIsFocused(true);
            console.log(
              "Hidden paste input focused. Now press CTRL+V to paste."
            );
          }}
          onBlur={() => setIsFocused(false)}
          onPaste={handlePaste}
          style={{ position: "absolute", left: "-1000px" }}
        />
      </div>
      {/* Right: Preview with paste/check icon */}
      <Stack sx={{ width: "100%", position: "relative" }}>
        <StyledBoxContent>
          {!item?.[`image_${place}_url`] ? (
            <Typography fontSize={"14px"}>No image uploaded</Typography>
          ) : (
            <img
              alt="Uploaded"
              src={item?.[`image_${place}_url`]}
              style={{
                height: "auto",
                objectFit: "cover",
                width: "50%",
              }}
            />
          )}
        </StyledBoxContent>
        <IconButton
          onClick={() => {
            if (pasteInputRef.current) {
              pasteInputRef.current.focus();
              // Focusing the hidden input triggers onFocus to set isFocused true.
            }
          }}
          sx={{ position: "absolute", bottom: 8, right: 8 }}
          color="primary"
          size="small"
        >
          {isFocused ? <CheckIcon /> : <ContentPasteIcon />}
        </IconButton>
      </Stack>
    </Stack>
  );
};

DropzoneWithPreview.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  place: PropTypes.number.isRequired,
  handleImageUpload: PropTypes.func.isRequired,
  clearSelectedFile: PropTypes.func.isRequired,
};

const CustomerConceptDetails = ({
  items,
  custom_items,
  updateItems,
  setLoading,
}) => {
  const handleImageUpload = (file, item, index, place) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("image", file);

    const token = getUserToken();
    fetch(`${apiConstants.API_URL}/images/sales-order`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        const newData = {
          ...item,
          // Save both the API returned image path and a blob URL for preview.
          [`image_${place}`]: data?.data?.path,
          [`image_${place}_url`]: URL.createObjectURL(file),
        };
        updateItems(index, newData);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const clearSelectedFile = (index, item, place) => {
    const newData = {
      ...item,
      [`image_${place}`]: "",
      [`image_${place}_url`]: "",
    };
    updateItems(index, newData);
  };

  return (
    <CardContent
      sx={{ textAlign: "left", minWidth: "500px", minHeight: "576px" }}
    >
      <Stack spacing={3}>
        <Typography fontSize={"24px"}>Concept Details</Typography>
        <Notification
          body="Please upload and assign concept designs for each item. You can use existing designs if already uploaded while creating the quotation."
          extra="Please Note"
        />
        <Stack spacing={2}>
          {items?.map((item, index) => (
            <div key={item?.id}>
              <Typography fontSize={"16px"}>
                {item?.name ? item.name : item.style}
              </Typography>
              {[1, 2, 3].map((place) => (
                <DropzoneWithPreview
                  key={place}
                  item={item}
                  index={index}
                  place={place}
                  handleImageUpload={handleImageUpload}
                  clearSelectedFile={clearSelectedFile}
                />
              ))}
            </div>
          ))}
        </Stack>
      </Stack>
    </CardContent>
  );
};

CustomerConceptDetails.propTypes = {
  items: PropTypes.array,
  updateItems: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
};

export default CustomerConceptDetails;
