import * as React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  Autocomplete,
  TextField,
  FormControl,
  Grid,
  IconButton,
  Typography,
  Divider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useStyles } from "../../../../Theme/Style";
import { useState, useEffect } from "react";
import { FieldArray, Form, Formik } from "formik";
import { Delete } from "@mui/icons-material";
import * as Yup from "yup";
import {
  useSubmitSublimationMutation,
  useUploadColorCodeMutation,
  useUploadLogoPlacementMutation,
  useGetSublimationQuery,
} from "../../../../services/api/production-order";
import { useNotification } from "../../../../Contexts/GlobalNotificationContext";
import { LoadingButton } from "@mui/lab";

const validationSchema = Yup.object({
  logoPlacementImage: Yup.array().min(1, "At least one image is required"),
  sublimatedColorCodeImages: Yup.array(),
  printerNumber: Yup.string().required("Printer Number is required"),
});

const SublimationComponent = ({ prodId }) => {
  const classes = useStyles();
  const { addNotification } = useNotification();
  const [loading, setLoading] = useState();

  const [uploadColorCodes, { isLoading: isLoadingColorCodeUpload }] =
    useUploadColorCodeMutation();

  const [uploadLogoPlacements, { isLoading: isLoadingLogoPlacementsUpload }] =
    useUploadLogoPlacementMutation();

  const [submitSublimation] = useSubmitSublimationMutation();

  const { data: sublimationData } = useGetSublimationQuery({
    id: prodId,
  });

  const [initialValues, setInitialValues] = useState({
    logoPlacementImage: [""],
    sublimatedColorCodeImages: [],
    printerNumber: "",
  });

  useEffect(() => {
    if (sublimationData) {
      console.log(sublimationData);

      setInitialValues({
        logoPlacementImage: sublimationData.logo_placement_image_urls.map(
          (url) => ({
            url,
          })
        ),
        sublimatedColorCodeImages: sublimationData.color_code_image_urls.map(
          (url) => ({
            url,
          })
        ),
        printerNumber: sublimationData.print_number,
      });
    }
  }, [sublimationData]);

  const handleImageChange = (event, replace, index) => {
    const file = event.currentTarget.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        replace(index, {
          file,
          url: reader.result,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const onSubmitData = async (values) => {
    try {
      setLoading(true);

      const logoPlacementsFiles = values.logoPlacementImage
        .filter(({ file }) => file)
        .map(({ file }) => file);

      const colorCodesFiles = values.sublimatedColorCodeImages
        .filter(({ file }) => file)
        .map(({ file }) => file);

      const uploadLogosPromises = logoPlacementsFiles.map(async (file) => {
        const formData = new FormData();
        formData.append("image", file);
        return await uploadLogoPlacements({ data: formData }).unwrap();
      });

      const uploadColorCodesPromisees = colorCodesFiles.map((file) => {
        const formData = new FormData();
        formData.append("image", file);
        return uploadColorCodes({ data: formData }).unwrap();
      });

      const uploadedLogos = await Promise.all(uploadLogosPromises);
      const uploadedColorCodes = await Promise.all(uploadColorCodesPromisees);

      const data = {
        print_number: values.printerNumber,
        logo_placement_images: uploadedLogos.map(({ path }) => path),
        color_code_images: uploadedColorCodes.map(({ path }) => path),
        production_order_id: prodId,
      };

      console.log({ data });

      await submitSublimation(data).unwrap();

      addNotification({
        message: "Sublimation Data is Submitted.",
        type: "success",
      });
    } catch (e) {
      console.log(e);

      const errors = e?.data?.error
        ? Object.values(e.data.error).flat().join(",")
        : "Something went wrong!!";

      addNotification({
        message: errors,
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={classes.accordionSummary}
      >
        <Grid container className={classes.accordionSummaryTextContainer}>
          <Grid item xs={4}>
            Sublimation
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            onSubmitData(values);
          }}
        >
          {({ values, setFieldValue, errors, touched }) => (
            <Form>
              {/* Logo Placement Section */}
              <Typography variant="subtitle1" marginBottom={2}>
                Logo Placement Section
              </Typography>

              <FieldArray name="logoPlacementImage">
                {({ push, remove, replace }) => (
                  <>
                    <Grid container spacing={2}>
                      {values.logoPlacementImage.map((_, index) => (
                        <Grid item xs={12} sm={6} key={index}>
                          <input
                            accept="image/*"
                            style={{ display: "none" }}
                            id={`logo-placement-image-${index}`}
                            type="file"
                            onChange={(event) =>
                              handleImageChange(event, replace, index)
                            }
                          />
                          {values.logoPlacementImage[index]?.url && (
                            <Box mt={2}>
                              <img
                                src={values.logoPlacementImage[index].url}
                                alt={`Logo Placement Preview ${index + 1}`}
                                style={{
                                  width: "100%",
                                  height: "200px",
                                  objectFit: "cover",
                                }}
                              />
                            </Box>
                          )}
                          <label htmlFor={`logo-placement-image-${index}`}>
                            <Button
                              variant="outlined"
                              component="span"
                              style={{
                                width: "100%",
                              }}
                            >
                              Upload Logo Placement Image {index + 1}
                              <IconButton onClick={() => remove(index)}>
                                <Delete />
                              </IconButton>
                            </Button>
                          </label>
                        </Grid>
                      ))}
                      <Grid item xs={12}>
                        <Button variant="contained" onClick={() => push({})}>
                          Add More Images
                        </Button>
                      </Grid>
                    </Grid>
                    {errors.logoPlacementImage &&
                      touched.logoPlacementImage && (
                        <div style={{ color: "red" }}>
                          {errors.logoPlacementImage}
                        </div>
                      )}
                  </>
                )}
              </FieldArray>
              <Divider sx={{ margin: "20px 0" }} />

              {/* Sublimated Color Code Section */}
              <Typography variant="subtitle1" marginBottom={2}>
                Sublimated Color Code
              </Typography>
              <FieldArray name="sublimatedColorCodeImages">
                {({ push, remove, replace }) => (
                  <>
                    <Grid container spacing={2} mt={2}>
                      {values.sublimatedColorCodeImages.map((_, index) => (
                        <Grid item xs={12} sm={6} key={index}>
                          <input
                            accept="image/*"
                            style={{ display: "none" }}
                            id={`sublimated-color-code-image-${index}`}
                            type="file"
                            onChange={(event) =>
                              handleImageChange(event, replace, index)
                            }
                          />
                          {values.sublimatedColorCodeImages[index]?.url && (
                            <Box mt={2}>
                              <img
                                src={
                                  values.sublimatedColorCodeImages[index].url
                                }
                                alt={`Sublimated Color Code Preview ${
                                  index + 1
                                }`}
                                style={{
                                  width: "100%",
                                  height: "200px",
                                  objectFit: "cover",
                                }}
                              />
                            </Box>
                          )}
                          <label
                            htmlFor={`sublimated-color-code-image-${index}`}
                          >
                            <Button
                              variant="outlined"
                              component="span"
                              style={{
                                width: "100%",
                              }}
                            >
                              Upload Sublimated Color Code Image {index + 1}
                              <IconButton onClick={() => remove(index)}>
                                <Delete />
                              </IconButton>
                            </Button>
                          </label>
                        </Grid>
                      ))}
                      <Grid item xs={12}>
                        <Button variant="contained" onClick={() => push({})}>
                          Add More Images
                        </Button>
                      </Grid>
                    </Grid>
                  </>
                )}
              </FieldArray>
              <Divider sx={{ margin: "20px 0" }} />

              {/* Printer Number Section */}
              <Typography variant="subtitle1" marginBottom={2}>
                Printer Number
              </Typography>
              <Grid item xs={12} mt={2}>
                <TextField
                  label="Printer Number"
                  name="printerNumber"
                  fullWidth
                  onChange={(e) =>
                    setFieldValue("printerNumber", e.target.value)
                  }
                  value={values.printerNumber}
                  error={touched.printerNumber && Boolean(errors.printerNumber)}
                  helperText={touched.printerNumber && errors.printerNumber}
                />
              </Grid>

              {/* Submit Button */}
              <Grid item xs={12} mt={4}>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  loading={loading}
                >
                  {`Save`}
                </LoadingButton>
              </Grid>
            </Form>
          )}
        </Formik>
      </AccordionDetails>
    </Accordion>
  );
};

export default SublimationComponent;
