import * as React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Grid,
  Button,
  FormControl,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useStyles } from "../../../Theme/Style";
import {
  useGetSpecialInstructionQuery,
  useSubmitSpecialInstructionMutation,
} from "../../../services/api/production-order";
import { useNotification } from "../../../Contexts/GlobalNotificationContext";

const SpecialInstructionComponent = ({ prodId }) => {
  const classes = useStyles();
  const { addNotification } = useNotification();

  // Query API for current special instructions using the production order id.
  const { data, isLoading, error } = useGetSpecialInstructionQuery({
    id: prodId,
  });
  const [submitSpecialInstruction] = useSubmitSpecialInstructionMutation();

  // Local state for the comment and error handling.
  const [specialComment, setSpecialComment] = React.useState("");
  const [localError, setLocalError] = React.useState({
    status: false,
    message: "",
  });

  // When API data is available, initialize the comment.
  React.useEffect(() => {
    if (data?.specialInstruction) {
      setSpecialComment(data.specialInstruction);
    }
  }, [data]);

  // Handle submitting the comment.
  const handleCommentSubmit = async () => {
    if (specialComment.trim() === "") {
      setLocalError({ status: true, message: "Please enter comment" });
      return;
    }
    try {
      await submitSpecialInstruction({
        production_order_id: prodId,
        comment: specialComment,
      }).unwrap();
      addNotification({
        message: "Special instructions saved successfully!",
        type: "success",
      });
    } catch (err) {
      addNotification({
        message: "Failed to save special instructions.",
        type: "error",
      });
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ textAlign: "center", padding: "20px" }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error" sx={{ textAlign: "center", padding: "20px" }}>
        Failed to load special instructions.
      </Typography>
    );
  }

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ backgroundColor: "#512879" }}
      >
        <Grid container className={classes.accordionSummaryTextContainer}>
          <Grid item xs={4}>
            Special Instructions
          </Grid>
          <Grid item xs={4}>
            特别说明
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <Box>
          Please specify if you have any additional requests, special
          instructions, changes etc.
        </Box>
        <Box>请说明您是否有任何其他要求、特殊说明、更改等。</Box>
        <FormControl sx={{ minWidth: 120, width: "100%" }}>
          <TextField
            className={classes.textField}
            id="standard-multiline-static"
            label="Your Comments | 你的评论"
            multiline
            rows={4}
            variant="standard"
            value={specialComment}
            onChange={(e) => {
              setSpecialComment(e.target.value);
              setLocalError({ status: false, message: "" });
            }}
          />
        </FormControl>
        {localError.status && (
          <Box sx={{ mt: 1 }}>
            <Typography sx={{ color: "red", fontSize: 12 }}>
              {localError.message}
            </Typography>
          </Box>
        )}
        <Box className={classes.buttonContainer}>
          <Button
            variant="contained"
            className={classes.submitBtn}
            onClick={handleCommentSubmit}
          >
            Save
          </Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default SpecialInstructionComponent;
