import * as React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Grid,
  Button,
  TextField,
  FormControl,
  Autocomplete,
  Typography,
  Card,
  CardMedia,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import { useStyles } from "../../../../Theme/Style";
import {
  useGetLabelsQuery,
  useSubmitLabelsMutation,
} from "../../../../services/api/production-order";
import { useNotification } from "../../../../Contexts/GlobalNotificationContext";
import CircularProgress from "@mui/material/CircularProgress";

const LabelsComponent = ({ prodId }) => {
  const classes = useStyles();
  const { addNotification } = useNotification();

  const {
    data: labelsData,
    isLoading,
    error,
  } = useGetLabelsQuery({ id: prodId });
  const [submitLabels] = useSubmitLabelsMutation();

  // Extract available label options from the API response.
  const labelOptions = labelsData?.options || [];

  // Setup Autocomplete default props.
  const defaultProps = {
    options: labelOptions,
    getOptionLabel: (option) => option?.name || "",
  };

  // Local state for label rows.
  const [labelValues, setLabelValues] = React.useState([]);

  // When the API data is fetched, initialize labelValues with the current labels.
  React.useEffect(() => {
    if (labelsData?.labels) {
      const oldLabels = labelsData.labels.map((label) => ({
        label: label.label,
        preview: label.label.image_url,
        url: label.label.image_url,
      }));
      setLabelValues(oldLabels);
    }
  }, [labelsData]);

  // Adds a new (empty) row.
  const addNewRow = () => {
    const newLabelRow = { label: null, preview: "", url: "" };
    setLabelValues([...labelValues, newLabelRow]);
  };

  // Update a row’s field.
  const handleChange = (event, name, index, value) => {
    const updatedRows = [...labelValues];
    updatedRows[index][name] = value;
    // If updating the label selection, store the image URL from the chosen option.
    if (name === "label") {
      updatedRows[index].url = value?.image_url || "";
    }
    setLabelValues(updatedRows);
  };

  // Delete a row.
  const deleteRow = (index) => {
    const updatedRows = [...labelValues];
    updatedRows.splice(index, 1);
    setLabelValues(updatedRows);
  };

  // Validate and submit the labels.
  const handleLabelSubmit = async () => {
    const isValid = labelValues.every((row) => row.label && row.url);
    if (!isValid) {
      addNotification({ message: "Please fill all the values", type: "error" });
      return;
    }
    try {
      await submitLabels({
        production_order_id: prodId,
        labels: labelValues,
      }).unwrap();
      addNotification({
        message: "Labels submitted successfully!",
        type: "success",
      });
    } catch (err) {
      addNotification({ message: "Failed to submit labels.", type: "error" });
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ textAlign: "center", padding: "20px" }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error" sx={{ textAlign: "center", padding: "20px" }}>
        Failed to load labels.
      </Typography>
    );
  }

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ backgroundColor: "#512879" }}
      >
        <Grid container className={classes.accordionSummaryTextContainer}>
          <Grid item xs={4}>
            Labels
          </Grid>
          <Grid item xs={4}>
            标签
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>
        {/* Table Header */}
        <Box className={classes.tableHeader}>
          <Grid container gap={2}>
            <Grid item xs={6} className={classes.cell}>
              <Box>Label</Box>
              <Box>标签</Box>
            </Grid>
            <Grid item xs={4} className={classes.cell}>
              <Box>Preview</Box>
              <Box>预览</Box>
            </Grid>
            <Grid item xs={1} className={classes.cell}></Grid>
          </Grid>
        </Box>
        {/* Render each label row */}
        {labelValues.map((row, index) => {
          const { label, url } = row;
          return (
            <Box key={index} sx={{ marginY: 2 }}>
              <Grid container gap={2} alignItems="center">
                <Grid item xs={6}>
                  <FormControl
                    variant="standard"
                    className={classes.tableBodyCell}
                  >
                    <Autocomplete
                      {...defaultProps}
                      id={`label-${index}`}
                      value={label}
                      autoComplete
                      includeInputInList
                      onChange={(event, value) =>
                        handleChange(event, "label", index, value)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select"
                          variant="standard"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <Card sx={{ maxHeight: 200 }}>
                    {url ? (
                      <CardMedia
                        component="img"
                        image={url}
                        height={200}
                        alt="Label Preview"
                      />
                    ) : (
                      <Box
                        sx={{
                          height: 200,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant="caption">No Preview</Typography>
                      </Box>
                    )}
                  </Card>
                </Grid>
                <Grid item xs={1}>
                  <Button onClick={() => deleteRow(index)}>
                    <RemoveCircleOutline />
                  </Button>
                </Grid>
              </Grid>
            </Box>
          );
        })}
        {/* Buttons for adding a new row and saving the data */}
        <Box sx={{ mt: 2, display: "flex", gap: 2 }}>
          <Button variant="outlined" onClick={addNewRow}>
            <AddCircleOutline sx={{ paddingRight: "10px" }} /> Add New Row
          </Button>
          <Button variant="contained" onClick={handleLabelSubmit}>
            Save
          </Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default LabelsComponent;
