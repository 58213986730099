import * as React from "react";
import { styled } from "@mui/material/styles";
import { Paper, Box, Button } from "@mui/material";
import OrderItemDetailsComponent from "./OrderItemDetails";

import SizeAndNamesComponent from "./SizeAndNamesComponent";

import { ArrowForward } from "@mui/icons-material";

import { PreProductionApprovalDetailsComponent } from "./PreProdDetailsComponent";
import { PRE_PRODUCTION_APPROVAL } from "../../../constants/Constants";

import EmproideryComponent from "./components/EmproideryComponent";
import SublimationComponent from "./components/SublimationComponent";
import FabricDetailsComponent from "./components/FabricDetailsComponent";
import TrimDetailsComponent from "./components/TrimDetailsComponent";
import LabelComponent from "./components/LabelComponent";
import SpecialInstructionComponent from "./components/SpecialInstructionComponent";
import SpecificationComponent from "./components/SpecificationComponent";

const Content = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

const ButtonStyle = styled(Button)({
  boxShadow: "none",
  border: "1px solid",
  backgroundColor: "#12830b",
  borderColor: "#12830b",
  width: "50%",
  height: "70px",
  fontWeight: "700",
  fontSize: 16,
  color: "#fff",
  "&:hover": {
    borderColor: "#12830b",
    backgroundColor: "#12830b",
    color: "#fff",
  },
  "&:disabled": {
    borderColor: "#5E5E5E",
    backgroundColor: "#5E5E5E",
    color: "#fff",
    opacity: 0.5,
  },
  "&:active": {},
  "&:focus": {},
});

const OrderItemsContainer = (props) => {
  const {
    id,
    productionOrder,
    activeItem,
    colors,
    fabrics,
    trims,
    poFields,
    nameAndSizes,
    patterns,
    setPatternId,
    sizeCounts,
    labels,
    selectedPattern,
    selectedPatternObj,
    patternSizeAdultHeader,
    patternSizeChildHeader,
    selectedPatternImage,
    setSpecialComment,
    specialComment,
    handleSubmit,
    handleStoreStages,
    stages,
    activeStep,
    handleProductionStatus,
    productionStatus,
    buttonText,
    setActiveItem,
    positions,
    preProdApprovals,
    handleTemplateChange,
    templates,
  } = props;
  const passedStageData = activeItem?.sales_order_item_passed_stages;

  const disabledBtn =
    productionStatus === "Approved" &&
    passedStageData[passedStageData?.length - 1]?.stage.id !== 5;
  const isPrototypeApproved =
    preProdApprovals.prototypeApproval?.status ===
    PRE_PRODUCTION_APPROVAL.STATUS.APPROVED;

  const isSkipped =
    preProdApprovals.prototypeApproval?.status ===
    PRE_PRODUCTION_APPROVAL.STATUS.SKIPPED;

  return (
    <Content>
      <OrderItemDetailsComponent
        id={id}
        productionOrder={productionOrder}
        activeItem={activeItem}
        handleStoreStages={handleStoreStages}
        stages={stages}
        activeStep={activeStep}
        productionStatus={productionStatus}
        handleTemplateChange={handleTemplateChange}
        template={templates[activeItem?.id]}
      />
      <Box sx={{ padding: "0 16px 50px", position: "relative" }}>
        <FabricDetailsComponent
          handleSubmit={handleSubmit}
          prodId={activeItem?.production_order?.id}
        />
        <TrimDetailsComponent
          handleSubmit={handleSubmit}
          prodId={activeItem?.production_order?.id}
        />
        <LabelComponent
          handleSubmit={handleSubmit}
          prodId={activeItem?.production_order?.id}
        />
        {nameAndSizes?.length && sizeCounts?.length && (
          <SizeAndNamesComponent
            activeItem={activeItem}
            nameAndSizes={nameAndSizes}
            sizeCounts={sizeCounts}
          />
        )}
        <SpecialInstructionComponent
          handleSubmit={handleSubmit}
          prodId={activeItem?.production_order?.id}
        />
        <SpecificationComponent
          handleSubmit={handleSubmit}
          prodId={activeItem?.production_order?.id}
        />

        <EmproideryComponent
          handleSubmit={handleSubmit}
          prodId={activeItem?.production_order?.id}
        />
        <SublimationComponent
          handleSubmit={handleSubmit}
          prodId={activeItem?.production_order?.id}
        />
        <Box
          sx={{
            marginTop: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "right",
            width: "100%",
          }}
        >
          <ButtonStyle
            variant="contained"
            onClick={handleProductionStatus}
            disabled={disabledBtn || !(isPrototypeApproved || isSkipped)}
          >
            {buttonText}
            <ArrowForward sx={{ marginLeft: "12px" }} />
          </ButtonStyle>
        </Box>
      </Box>
    </Content>
  );
};

export default OrderItemsContainer;
