import * as React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Grid,
  Button,
  Stack,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  accordion: {
    boxShadow: "none !important",
    paddingTop: "50px",
  },
  accordionSummaryTextContainer: {
    color: "#fff",
  },
  cell: {
    textAlign: "left",
    fontSize: 14,
    fontWeight: 700,
  },
  cellValue: {
    textAlign: "left",
    fontSize: 14,
  },
  row: {
    borderBottom: "1px solid #efefef",
    padding: "8px 16px",
  },
  totalCounter: {
    fontSize: "14px",
    textAlign: "left",
  },
  sizeCountContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
  },
  sizeCount: {
    backgroundColor: "rgba(0, 0, 0, 0.12)",
    display: "flex",
    borderRadius: "20px",
    minWidth: "50px",
    textAlign: "center",
    fontSize: "14px",
    alignItems: "center",
  },
  sizeCountIcon: {
    backgroundColor: "#512879",
    color: "#fff",
    minWidth: "20px",
    height: "20px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "14px",
    margin: "3px 6px 3px 3px",
    textTransform: "capitalize",
  },
  sizeCountValue: {
    margin: "3px 6px 3px 3px",
  },
});

const SizeAndNamesComponent = (props) => {
  const classes = useStyles();
  const { activeItem, nameAndSizes, sizeCounts } = props;

  const downloadUsingAnchorElement = async () => {
    const anchor = document.createElement("a");
    anchor.href = activeItem?.custom_size_url;
    document.body.appendChild(anchor);
    anchor.click();
  };

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ backgroundColor: "#512879" }}
      >
        <Grid container className={classes.accordionSummaryTextContainer}>
          <Grid item xs={4}>
            Sizes and Names
          </Grid>
          <Grid item xs={4}>
            尺寸和名称
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>
        {activeItem?.flag_name_no !== 0 && (
          <>
            <Box
              sx={{ background: "#000", color: "#fff", padding: "12px 16px" }}
            >
              <Grid container>
                <Grid item xs={4} className={classes.cell}>
                  <Box>Garment Size</Box>
                  <Box>服装尺码</Box>
                </Grid>
                <Grid item xs={4} className={classes.cell}>
                  <Box>Number</Box>
                  <Box>数字</Box>
                </Grid>
                <Grid item xs={4} className={classes.cell}>
                  <Box>Name</Box>
                  <Box>姓名</Box>
                </Grid>
              </Grid>
            </Box>
            {nameAndSizes?.map((item, index) => (
              <Box key={index} className={classes.row}>
                <Grid container>
                  <Grid item xs={4} className={classes.cellValue}>
                    {item.sizeCode}
                  </Grid>
                  <Grid item xs={4} className={classes.cellValue}>
                    {item.number}
                  </Grid>
                  <Grid item xs={4} className={classes.cellValue}>
                    {item.name}
                  </Grid>
                </Grid>
              </Box>
            ))}
          </>
        )}
        <Box sx={{ background: "#f1f1f2", padding: "16px" }}>
          <Grid container>
            <Grid item xs={8}>
              <Box className={classes.totalCounter}>
                {`Total: ${activeItem?.qty} units  总计：${activeItem?.qty} 个单位`}
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box className={classes.sizeCountContainer}>
                {sizeCounts?.map((count, index) => (
                  <Box key={index} className={classes.sizeCount}>
                    <Box className={classes.sizeCountIcon}>
                      {count.sizeCode}
                    </Box>
                    <Box className={classes.sizeCountValue}>
                      {count.quantity}
                    </Box>
                  </Box>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ background: "#f1f1f2", padding: "16px" }}>
          <Grid container>
            <Grid item xs={12}>
              <Box className={classes.totalCounter}>
                {activeItem?.custom_size_url && (
                  <iframe
                    src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
                      activeItem?.custom_size_url
                    )}`}
                    title="Excel Preview"
                    width="100%"
                    height="100%"
                    style={{ border: "none" }}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Stack direction={"row"} alignItems={"center"} sx={{ mt: 2 }}>
          {activeItem?.custom_size_url && (
            <Button onClick={downloadUsingAnchorElement}>
              Download Sizes and Names Excel File
            </Button>
          )}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default SizeAndNamesComponent;
