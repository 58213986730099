import styled from "@emotion/styled";
import {
  Box,
  Button,
  Input,
  Stack,
  Typography,
  CircularProgress,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Stepper from "../../../Common/Stepper";
import { COMPLETE, NEXT, HORIZONTAL } from "../../../constants/Constants";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SalesOrderItems from "../../../components/SalesOrder/SalesOrderItems";
import SalesSummeryCustomer from "../../../components/SalesOrderCustomer/SalesSummeryCustomer";
import ItemSummeryCustomer from "../../../components/SalesOrderCustomer/ItemSummeryCustomer";
import { useParams, Link, useNavigate } from "react-router-dom";
import SuccessModal from "../../../Common/Modals/SuccessModal";
import FailedModal from "../../../Common/Modals/FailedModal";
import AlertToast from "../../../components/common/Toast";
import { apiConstants } from "../../../constants";
import { authHeader, getUserToken } from "../../../helpers";
import ProgressCircularWithBackdrop from "../../../Common/ProgressCircular/ProgressCircularWithBackdrop";
import Intro from "../../atoms/Intro/Intro.component";
import { getCurrentUser } from "../../../helpers";

const StyledContainer = styled(Box)({
  width: "100%",
  minWidth: "500px",
  border: "1px dashed #C4C4C4",
  height: "379px",
});

const StyledStepperContainer = styled(Stack)({
  width: "200px",
  borderRight: "1px solid #C4C4C4",
  minHeight: "500px",
});

const StyledTableContainer = styled(Stack)({
  marginTop: "10px",
});

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.neutral.light,
  height: "60px",
}));

const StyledImage = styled("img")({
  display: "block",
  height: "85%",
  objectFit: "fill",
  paddingLeft: "10px",
});

function AdminAcceptSalesOrder() {
  const [sizes, setSizes] = useState([]);
  const [data, setData] = useState({
    items: [],
  });
  // Initialize steps with only the final (complete) step.
  // Later the item names will be prepended to create the full steps list.
  const [steps, setSteps] = useState([COMPLETE]);
  const [activeStep, setActiveStep] = useState(0);
  const [mounted, setMounted] = useState(false);
  const [isSuccess, setIssuccess] = useState(false);
  const [success, setSuccess] = useState("");
  const [comments, setComments] = useState("");
  const [loading, setLoading] = useState(true);
  const [totalDelivery, setTotalDelivery] = useState(0);
  const [openProgressCircular, setOpenProgressCircular] = useState(false);
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(false);
  const [orderItemsError, setOrderItemsError] = useState({
    status: false,
    message: "",
    index: 0,
  });

  const currentUserData = getCurrentUser();
  const currentUserId = currentUserData?.id;

  let { id } = useParams();
  const navigate = useNavigate();

  const [toastState, setToastState] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });
  const [toastMessage, setToastMessage] = useState("");
  const handleToastClose = () =>
    setToastState({ open: false, vertical: "top", horizontal: "right" });

  const getItem = useCallback(() => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    fetch(`${apiConstants.API_URL}/sales-orders/${id}`, requestOptions).then(
      (response) => {
        response.text().then((text) => {
          const resData = text && JSON.parse(text);
          // Set the delivery charges if available
          setTotalDelivery(resData?.data?.estimated_shipping_charges);

          // Fetch sizes
          fetch(`${apiConstants.API_URL}/sizes`, requestOptions).then(
            (response) => {
              response.text().then((text) => {
                const sizesRes = text && JSON.parse(text);
                setSizes(sizesRes?.data);
                const sizesArray = sizesRes?.data;
                if (resData?.data.comment) {
                  setComments(resData?.data.comment);
                }

                const newDataItems = resData?.data.items.map((item) => {
                  let arrangedItems = [];
                  let itemDetailsWithQuantityArrangedItems = [];

                  if (
                    item?.item_details.length !== 0 &&
                    item?.flag_name_no === 1
                  ) {
                    item?.item_details.forEach((q) => {
                      sizesArray?.forEach((s) => {
                        if (q.size?.id === s.id) {
                          const obj = {
                            ...q,
                            size_id: q.size?.id,
                            selectedSize: { id: s.id, name: s.name },
                          };
                          arrangedItems.push(obj);
                        }
                      });
                    });
                    itemDetailsWithQuantityArrangedItems = [
                      {
                        size_id: 0,
                        quantity: "",
                        selectedSize: { id: 0, name: "" },
                      },
                    ];
                  } else if (
                    item?.item_details.length !== 0 &&
                    item?.flag_name_no === 0
                  ) {
                    let sizeWithQuantity = [];
                    item?.item_details.forEach((q) => {
                      sizesArray?.forEach((s) => {
                        if (q.size?.id === s.id) {
                          const obj = {
                            size_id: q.size?.id,
                            quantity: 1,
                            selectedSize: { id: s.id, name: s.name },
                          };
                          sizeWithQuantity.push(obj);
                        }
                      });
                    });

                    let hash = Object.create(null);
                    let result = [];

                    sizeWithQuantity.forEach(function (o) {
                      if (!hash[o.size_id]) {
                        hash[o.size_id] = {
                          size_id: o.size_id,
                          quantity: 0,
                          selectedSize: o.selectedSize,
                        };
                        result.push(hash[o.size_id]);
                      }
                      hash[o.size_id].quantity += +o.quantity;
                    });
                    itemDetailsWithQuantityArrangedItems = result;

                    for (let i = 0; i < item?.qty; i++) {
                      let newObt = {
                        number: "",
                        name: "",
                        size_id: 0,
                        id: "",
                        selectedSize: { id: 0, name: "" },
                      };
                      arrangedItems.push(newObt);
                    }
                  } else if (item?.item_details.length === 0) {
                    for (let i = 0; i < item?.qty; i++) {
                      let newObt = {
                        number: "",
                        name: "",
                        size_id: 0,
                        id: "",
                        selectedSize: { id: 0, name: "" },
                      };
                      arrangedItems.push(newObt);
                    }
                    itemDetailsWithQuantityArrangedItems = [
                      {
                        size_id: 0,
                        quantity: "",
                        selectedSize: { id: 0, name: "" },
                      },
                    ];
                  }

                  let newOb = {
                    ...item,
                    price_id: item?.price?.id,
                    color_id: item?.color?.id,
                    item_details: arrangedItems,
                    itemDetailsWithQuantity:
                      itemDetailsWithQuantityArrangedItems,
                    item_details_file_path:
                      item?.item_details_file_path === null
                        ? ""
                        : item?.item_details_file_path,
                    item_details_file_url:
                      item?.item_details_file_url === null
                        ? ""
                        : item?.item_details_file_url,
                  };
                  return newOb;
                });

                const newDataObject = {
                  ...resData?.data,
                  items: newDataItems,
                };

                setData(newDataObject);

                // Build the steps: prepend the item names then add the final step (COMPLETE)
                const itemSteps = resData?.data?.items.map(
                  (item) => item?.price?.item?.name
                );
                setSteps([...itemSteps, COMPLETE]);
              });
            }
          );
        });
      }
    );
  }, [id]);

  // Determine the current item (if any)
  const displayData = data?.items[activeStep] ?? {};

  // Define a “review” (final) step as when activeStep equals the number of items.
  const isReviewStep =
    data.items && data.items.length > 0 && activeStep === data.items.length;

  // Set title and images based on whether we’re reviewing the order or editing an item.
  const title = isReviewStep ? "Review Order" : displayData?.price?.item?.name;
  const image = !isReviewStep ? displayData?.image_url : "";
  const image2 = !isReviewStep ? displayData?.image_2_url : "";
  const image3 = !isReviewStep ? displayData?.image_3_url : "";
  const buttonText = isReviewStep ? "CONFIRM SALES ORDER" : NEXT;

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
      getItem();
    }
    // Remove extra stage flags now that we no longer use a purchase order step.
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, [getItem, mounted]);

  const submitAction = () => {
    setOpenProgressCircular(true);
    const {
      items,
      order_method,
      organization,
      quotation,
      status,
      estimated_shipping_charges,
    } = data;

    if (status.id === 2) {
      setToastMessage("Order is already accepted");
      setToastState({
        open: true,
        vertical: "top",
        horizontal: "right",
      });
    } else {
      const oldItems = items.map((item) => {
        let newObt;
        if (item?.flag_name_no) {
          const formateArray = item?.item_details.map((c) => {
            return {
              number: c.number,
              name: c.name,
              size_id: c.size_id,
              id: c.id ? c.id : "",
            };
          });

          newObt = {
            ...item,
            item_details: formateArray,
          };
        } else {
          let array = [];
          item?.itemDetailsWithQuantity?.forEach((q) => {
            for (let i = 0; i < q.quantity; i++) {
              const obj = {
                number: "",
                name: "",
                size_id: q.size_id,
                id: q.id ? q.id : "",
              };
              array.push(obj);
            }
          });
          newObt = {
            ...item,
            item_details: array,
          };
        }
        // Remove extra properties not needed in submission
        delete newObt.sales_order_item_extra_charges;
        return newObt;
      });

      oldItems.forEach(function (v) {
        delete v.itemDetailsWithQuantity;
        if (v?.item_details_file_path === "") {
          delete v.item_details_file_path;
          delete v.item_details_file_url;
        }
      });

      submitSalesOrder("", oldItems);
    }
  };

  const submitSalesOrder = (imagePath, oldItems) => {
    const {
      items,
      order_method,
      organization,
      quotation,
      status,
      estimated_shipping_charges,
    } = data;

    const reqObe = {
      order_method_id: order_method?.id,
      invoice_id: null,
      organization_id: organization?.id,
      quotation_id: quotation?.id,
      status_id: 2,
      comment: comments,
      items: oldItems,
      estimated_shipping_charges: estimated_shipping_charges,
      sales_agent_id: currentUserId,
    };

    const requestOptions = {
      method: "PUT",
      headers: authHeader(),
      body: JSON.stringify(reqObe),
    };
    fetch(`${apiConstants.API_URL}/sales-orders/${id}`, requestOptions).then(
      (response) => {
        response.text().then((text) => {
          const res = text && JSON.parse(text);
          if (!response.ok) {
            if (response.status === 401) {
              // logout() if needed
            }
            const error = (res && res.error) || response.statusText;
            const errorMessage = Object.values(error).map(
              (errorKey) => errorKey[0]
            );
            setOpenProgressCircular(false);
            setToastMessage(errorMessage);
            setToastState({
              open: true,
              vertical: "top",
              horizontal: "right",
            });
          } else {
            setOpenProgressCircular(false);
            setSuccess(res?.data?.id);
            setIssuccess(true);
            // Advance beyond the last step
            setActiveStep(steps.length);
            setDisabledSubmitBtn(true);
          }
        });
      }
    );
  };

  const handleCloseSuccessModal = () => {
    setIssuccess(true);
    // Redirect to '/admin/sales-orders/' page
    navigate(`/admin/sales-orders`);
  };

  const nextAction = () => {
    if (!isReviewStep) {
      setActiveStep(activeStep + 1);
      // Mark the step as edited if needed
      // (optional: you can remove or adjust this flag as desired)
      // For example: setEditStatus(true);
    } else {
      submitAction();
    }
  };

  const preAction = () => {
    setActiveStep(activeStep - 1);
  };

  const updateComments = (e) => {
    const { target } = e;
    setComments(target?.value);
  };

  let breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to="/admin/quotations">
      All Quotations
    </Link>,
    <Typography key="3">{"View Quotation"}</Typography>,
  ];

  return (
    <>
      <Stack direction="column" spacing={5}>
        <Intro
          pageTitle={`Accept Sales Order`}
          pageTitleShort={"Accept Sales Order"}
          breadcrumbs={breadcrumbs}
          additionalWidgets=""
          sx={{ margin: "50px 0" }}
        />
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "400px",
            }}
          >
            <CircularProgress size={50} />
          </Box>
        ) : (
          <Stack direction="column" spacing={5}>
            <Box>
              <Stepper
                activeStep={activeStep}
                orientation={HORIZONTAL}
                steps={steps}
                alternativeLabel={false}
              />
            </Box>
            <Stack direction="row" spacing={5}>
              <Stack style={{ textAlign: "left" }} spacing={4}>
                <Typography fontSize="28px" fontWeight={500}>
                  {title}
                </Typography>
                {/* Render the main item/image editing section if not in review step.
                    In the review step, show a simple message (or add your own review component). */}
                {!isReviewStep ? (
                  <>
                    <StyledContainer
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        flexWrap: "nowrap",
                        overflowX: "auto",
                      }}
                    >
                      {!image ? (
                        <div>No Image</div>
                      ) : (
                        <StyledImage
                          src={image}
                          style={{ maxWidth: "100%", maxHeight: "20rem" }}
                        />
                      )}
                      {!image2 ? (
                        <div>No Image</div>
                      ) : (
                        <StyledImage
                          src={image2}
                          style={{ maxWidth: "100%", maxHeight: "20rem" }}
                        />
                      )}
                      {!image3 ? (
                        <div>No Image</div>
                      ) : (
                        <StyledImage
                          src={image3}
                          style={{ maxWidth: "100%", maxHeight: "20rem" }}
                        />
                      )}
                    </StyledContainer>
                    <StyledTableContainer spacing={4}>
                      <SalesOrderItems
                        sizes={sizes}
                        item_details={displayData?.item_details}
                        displayData={displayData}
                        setData={setData}
                        data={data}
                        activeStep={activeStep}
                        // If you no longer need editStatus, you may remove it.
                        editStatus={true}
                        error={orderItemsError}
                        setError={setOrderItemsError}
                        setDisabledSubmitBtn={setDisabledSubmitBtn}
                      />
                      <Stack>
                        <Typography
                          style={{ fontSize: "20px", fontWeight: 700 }}
                        >
                          Comments
                        </Typography>
                        <Typography style={{ fontSize: "15px" }}>
                          Please specify if you have any additional request,
                          special instructions or changes, etc.
                        </Typography>
                        <Stack style={{ marginTop: "20px" }} spacing={4}>
                          <Typography style={{ fontSize: "15px" }}>
                            Your Comments
                          </Typography>
                          <Input value={comments} onChange={updateComments} />
                        </Stack>
                      </Stack>
                    </StyledTableContainer>
                  </>
                ) : (
                  <Box sx={{ padding: "1rem", border: "1px solid #C4C4C4" }}>
                    <Typography variant="h6">
                      Please review your order details on the right side.
                    </Typography>
                    <Typography variant="body2">
                      If everything looks correct, click the "CONFIRM SALES
                      ORDER" button.
                    </Typography>
                  </Box>
                )}
              </Stack>

              <Stack spacing={2}>
                {/* Display the item summary only when editing an item */}
                <Box style={{ marginTop: "70px" }}>
                  {!isReviewStep && displayData && (
                    <ItemSummeryCustomer {...displayData} />
                  )}
                </Box>

                {/* Sales order summary is shown on the right side */}
                <SalesSummeryCustomer
                  data={data}
                  displayData={displayData}
                  activeStep={activeStep}
                  totalDelivery={totalDelivery}
                />

                <StyledButton
                  variant="contained"
                  color="success"
                  endIcon={<ArrowForwardIcon />}
                  onClick={nextAction}
                  disabled={disabledSubmitBtn}
                >
                  {buttonText}
                </StyledButton>
                {activeStep > 0 && (
                  <Button startIcon={<ArrowBackIcon />} onClick={preAction}>
                    BACK
                  </Button>
                )}
              </Stack>
            </Stack>
            <ProgressCircularWithBackdrop open={openProgressCircular} />
            <SuccessModal
              title={"Sales Order Accepted Successfully!"}
              open={isSuccess}
              handleClose={handleCloseSuccessModal}
              body={`Sales Order #${success} is accepted successfully. Your account manager will contact and guide you through the next steps`}
              extra="Thank you for your order."
            />
            <FailedModal />
            <AlertToast
              toastMessage={toastMessage}
              toastState={toastState}
              handleClose={handleToastClose}
            />
          </Stack>
        )}
      </Stack>
    </>
  );
}

export default AdminAcceptSalesOrder;
